import React, {useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

const Resume = () => {
    useEffect(() => {
        AOS.init({duration: 1000})
    }, [])

    return (
        <section className="resume-area page-section scroll-to-page" id="resume">
            <div className="custom-container">
                <div className="resume-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="las la-briefcase"></i> Resume
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>Education & <span>Experience</span></h1>
                    </div>


                    <div className="resume-timeline">
                        <div className="item scroll-animation" data-aos='fade-right'>
                            <span className="date">2020 - Present</span>
                            <h2>Frontend Developer</h2>
                            <p>ASSIST Software</p>
                            <p>Suceava, Romania</p>
                            <ol>
                                <li><b>Designed and developed web applications:</b> Worked on designing and developing
                                    front-end applications, focusing on creating responsive and high-performing web
                                    solutions. Utilized modern web technologies to deliver robust applications that meet
                                    client requirements.
                                </li>
                                <li><b>Led and mentored teams:</b> Led a team of developers on key projects, ensuring
                                    effective task distribution, code quality, and project delivery timelines. Mentored
                                    junior developers, providing guidance, technical training, and support to foster
                                    their growth and integration into the team.
                                </li>
                                <li><b>Collaborated with cross-functional teams:</b> Collaborated closely with product
                                    managers, designers, and back- end developers to define and implement new features,
                                    ensuring seamless integration and a cohesive user experience.
                                </li>
                                <li><b>Project management tools:</b> Utilized tools like JIRA and Bitbucket for task
                                    assignment and project management, ensuring efficient workflow and clear
                                    communication across the team. Maintained transparency in project progress and
                                    effectively managed deadlines.
                                </li>
                                <li><b>Testing and quality assurance:</b> Developed and executed unit tests using
                                    testing frameworks to ensure the reliability and quality of code. Created
                                    integration tests to validate end-to-end application functionality, reducing bugs
                                    and improving overall system stability.
                                </li>
                                <li><b>UI/UX collaboration:</b> Worked with UI/UX designers to translate design concepts
                                    into functional user interfaces. Focused on implementing best design practices,
                                    resulting in intuitive and aesthetically pleasing web applications.
                                </li>
                                <li><b>Scalable application development:</b> Built scalable and high-performance
                                    applications using frameworks and libraries suited for various project needs,
                                    ensuring they could handle increased traffic and data loads efficiently.
                                </li>
                                <li><b>API integration:</b> Collaborated with back-end developers to integrate RESTful
                                    APIs, ensuring efficient communication between front-end interfaces and server-side
                                    data. Developed solutions to optimize data flow and application performance.
                                </li>
                                <li><b>Reusable components development:</b> Created reusable components to streamline
                                    development processes and maintain consistency across projects. Utilized tools like
                                    Storybook for component documentation and testing.
                                </li>
                                <li><b>Cloud services:</b> Deployed and managed applications on cloud platforms such as
                                    Vercel, implementing continuous integration for streamlined deployment processes.
                                </li>
                                <li><b>Data visualization:</b> Developed complex data visualizations to provide users
                                    with actionable insights. Created dynamic charts and graphs to represent data
                                    effectively, improving user engagement and understanding.
                                </li>
                                <li><b>Problem solving and optimization:</b> Actively engaged in problem-solving to
                                    address technical challenges and optimize project outcomes. Implemented best
                                    practices and innovative solutions to improve application functionality and user
                                    satisfaction.
                                </li>
                            </ol>
                        </div>
                        <div className="item scroll-animation" data-aos='fade-right'>
                            <span className="date">2023 - Present</span>
                            <h2>Network Administartor</h2>
                            <p>Zubcu Energy Group</p>
                            <p>Chișinău, Moldova</p>
                            <ol>
                                <li><b>Web maintenance:</b> Managed and maintained the company’s web pages, ensuring
                                    they were up-to-date, functional, and aligned with the latest company information.
                                    This involved regular updates, content management, and performance optimization to
                                    enhance user experience.
                                </li>
                                <li><b>Social media promotion:</b> Actively promoted the company through various social
                                    networks, developing and executing strategies to increase brand visibility and
                                    engagement. Created and shared content, managed interactions, and monitored social
                                    media metrics to assess the effectiveness of campaigns.
                                </li>
                                <li><b>Google business profile management:</b> Administered the company’s Google
                                    business profile, ensuring accurate information and enhancing the company’s online
                                    presence. Regularly updated business details, responded to customer reviews, and
                                    optimized the profile for better search engine visibility.
                                </li>
                                <li><b>Video documentation:</b> Took charge of filming the works carried out by the
                                    company, capturing key moments and project milestones. These videos were used for
                                    promotional purposes, internal documentation, and showcasing the company’s
                                    capabilities to clients and stakeholders.
                                </li>
                                <li><b>1C database maintenance:</b> Maintained and managed the company’s 1C database,
                                    ensuring data integrity and accessibility. This included regular data entry,
                                    updates, and troubleshooting to support business operations and decision-making
                                    processes.
                                </li>
                                <li><b>Mobile application development:</b> Developed a mobile task management
                                    application for employees, designed to streamline task assignment, tracking, and
                                    communication. This application improved operational efficiency, facilitated better
                                    coordination among employees, and contributed to the overall productivity of the
                                    company.
                                </li>
                            </ol>
                        </div>
                        <div className="item scroll-animation" data-aos='fade-right'>
                            <span className="date">2020 - Present</span>
                            <h2>Member of the Evaluation Committee</h2>
                            <p>Center of Excellence in Economics and Informatics</p>
                            <p>Chișinău, Moldova</p>
                            <ol>
                                <li><b>Coordination of diploma works:</b> Responsible for overseeing and coordinating
                                    diploma projects, ensuring that students meet academic requirements and standards.
                                    This role involves providing guidance to students throughout their project
                                    development, reviewing progress, and offering feedback to help refine their work.
                                </li>
                                <li><b>Evaluation of state exams and diploma theses:</b> Played a key role in evaluating
                                    state exams and assessing diploma theses. This involves reviewing student
                                    submissions, grading them based on established criteria, and
                                    providing constructive feedback. The evaluations ensure that academic standards are
                                    upheld and that students
                                    demonstrate the required knowledge and skills.
                                </li>
                                <li><b>Leadership as president of the evaluation committee:</b> For the past two
                                    academic years (2023 and 2024),
                                    served as the President of the Evaluation Committee. This leadership role includes
                                    organizing and leading the committee meetings, setting evaluation schedules,
                                    ensuring fair and unbiased assessments, and finalizing the results. It also involves
                                    collaborating with other committee members to maintain a high standard of
                                    evaluation.
                                </li>
                                <li><b>End-of-year responsibilities:</b> These tasks are primarily undertaken at the end
                                    of the academic year, reflecting a focused commitment to maintaining the integrity
                                    and quality of the academic assessment process.
                                </li>
                            </ol>
                        </div>
                        <div className="item scroll-animation" data-aos='fade-right'>
                            <span className="date">2017 - 2020</span>
                            <h2>Bachelor Degree</h2>
                            <p>Stefan cel Mare University</p>
                            <p>Suceava, Romania</p>
                        </div>
                        <div className="item scroll-animation" data-aos='fade-right'>
                            <span className="date">2019 - 2020</span>
                            <h2>Erasmus Mobility</h2>
                            <p>Moray College</p>
                            <p>Elgin, United Kingdom</p>
                        </div>
                        <div className="item scroll-animation" data-aos='fade-right'>
                            <span className="date">2018 - 2019</span>
                            <h2>Erasmus Mobility</h2>
                            <p>Business Academy and Higher Professional School</p>
                            <p>Valašské Meziříčí, Czech Republic</p>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Resume