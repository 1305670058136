import React, {useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

const Service = () => {
    useEffect(() => {
        AOS.init({duration: 1000})
    }, [])
    return (
        <section className="services-area page-section scroll-to-page" id="services">
            <div className="custom-container">
                <div className="services-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="las la-stream"></i> Services
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>My <span>Specializations</span></h1>
                    </div>

                    <div className="services-items">
                        <div className="service-item scroll-animation" data-aos='fade-up'>
                            <i className="las la-code"></i>
                            <h2>Development</h2>
                            <p>I build web applications using Angular, React, Next.js, Wix</p>
                            <span className="projects">10 Projects</span>
                        </div>
                        <div className="service-item scroll-animation" data-aos='fade-up'>
                            <i className="las la-bezier-curve"></i>
                            <h2>SEO</h2>
                            <p>Increase the traffic for your web application with SEO optimized</p>
                            <span className="projects">2 Projects</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Service
