import React, {useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Lightbox from 'lightbox2';
import 'lightbox2/dist/css/lightbox.min.css';
// import 'lightbox2/dist/js/lightbox.js';

export default function Portfolio() {
    useEffect(() => {
        AOS.init({duration: 1000});
        Lightbox.option({
            resizeDuration: 200,
            fadeDuration: 600,
            imageFadeDuration: 600,
            wrapAround: true
        });
    }, []);
    return (
        <section className="portfolio-area page-section scroll-to-page" id="portfolio">
            <div className="custom-container">
                <div className="portfolio-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="las la-grip-vertical"></i> portfolio
                        </h4>
                        <h1 className="scroll-animation">Featured <span>Projects</span></h1>
                        <p>Besides dozens of projects developed being employed for different areas of activity, all of
                            them
                            being in production, I worked on projects as a freelancer as well. These are som
                            examples: </p>
                    </div>

                    <div className="row portfolio-items">
                        <div className="col-md-12 scroll-animation" data-aos='fade-up'>
                            <div className="portfolio-item portfolio-full">
                                <div className="portfolio-item-inner">
                                    <a href="../assets/images/portfolio/portfolio1.png" data-lightbox="example-1">
                                        <img src="../assets/images/portfolio/portfolio1.png" alt="Portfolio"/>
                                    </a>

                                    <ul className="portfolio-categories">
                                        <li>
                                            <a href="/">React</a>
                                        </li>
                                        <li>
                                            <a href="/">Next.js</a>
                                        </li>
                                        <li>
                                            <a href="/">Node.js</a>
                                        </li>
                                        <li>
                                            <a href="/">Nest.js</a>
                                        </li>
                                        <li>
                                            <a href="/">Firebase</a>
                                        </li>
                                    </ul>
                                </div>
                                <h2><a href="https://www.zmagazin.md">ZMagazin - ECommerce Web Application & Admin
                                    Dashboard</a></h2>
                            </div>
                        </div>

                        <div className="col-md-6 scroll-animation" data-aos='fade-right'>
                            <div className="portfolio-item portfolio-half">
                                <div className="portfolio-item-inner">
                                    <a href="../assets/images/portfolio/portfolio2.png" data-lightbox="example-1">
                                        <img src="../assets/images/portfolio/portfolio2.png" alt="Portfolio"/>
                                    </a>

                                    <img src="../assets/images/portfolio2.jpg" alt="Portfolio"/>

                                    <ul className="portfolio-categories">
                                        <li>
                                            <a href="/">React</a>
                                        </li>
                                        <li>
                                            <a href="/">Firebase</a>
                                        </li>
                                    </ul>
                                </div>
                                <h2><a href="https://www.zubcu.md">Zubcu Energy Group Website</a></h2>
                            </div>
                        </div>

                        <div className="col-md-6 scroll-animation" data-aos='fade-left'>
                            <div className="portfolio-item portfolio-half">
                                <div className="portfolio-item-inner">
                                    <a href="../assets/images/portfolio/portfolio3.gif" data-lightbox="example-1">
                                        <img src="../assets/images/portfolio/portfolio3.gif" alt="Portfolio"/>
                                    </a>

                                    <ul className="portfolio-categories">
                                        <li>
                                            <a href="/">HTML</a>
                                        </li>
                                        <li>
                                            <a href="/">Javascript</a>
                                        </li>
                                        <li>
                                            <a href="/">jQuery</a>
                                        </li>
                                    </ul>
                                </div>
                                <h2><a href="https://www.sm-detailing.md">SM Detailing Logo & Website</a></h2>
                            </div>
                        </div>

                        <div className="col-md-12 scroll-animation" data-aos='fade-up'>
                            <div className="portfolio-item portfolio-half">
                                <div className="portfolio-item-inner">
                                    <a href="../assets/images/portfolio/portfolio4.gif" data-lightbox="example-1">
                                        <img src="../assets/images/portfolio/portfolio4.gif" alt="portfolio"/>
                                    </a>

                                    <ul className="portfolio-categories">
                                        <li>
                                            <a href="/">Wix</a>
                                        </li>
                                    </ul>
                                </div>
                                <h2><a href="https://www.igo-contruct.md">IGO Construct Logo & Ecommerce</a></h2>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}
