import React from 'react'
import RightSidebar from './Global-content/RightSidebar'
import Scrollnav from './Global-content/Scrollnav'
import LeftSidebar from './Global-content/LeftSidebar'
import MainWrapper from './MainWrapper'


const Global = () => {
    return (
      <div>
        <LeftSidebar/>
        <RightSidebar/>
        <Scrollnav/>
        <MainWrapper/>
    </div>
    )
  }

export default Global