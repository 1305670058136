import React, {useMemo} from 'react'

const LeftSidebar = () => {

    const currentYear = useMemo(() => {
        return new Date().getFullYear()
    }, [])

    return (
        <div className="left-sidebar">
            <div className="sidebar-header d-flex align-items-center justify-content-between">
                <img style={{maxWidth: 150}} src="./assets/images/logo.png" alt="Logo"/>
                <span className="designation">Frontend & 1C Developer</span>
            </div>
            <img className="me" src="./assets/images/me.png" alt="Me"/>
            <h2 className="name">Catalin Lupascu</h2>
            <h2 className="email">clupascu97@gmail.com</h2>
            <h2 className="address">Based in Chisinau, MD</h2>
            <p className="copyright">&copy; {currentYear} Catalin. All Rights Reserved</p>
            <ul className="social-profile d-flex align-items-center flex-wrap justify-content-center">
                <li>
                    <a href="https://www.linkedin.com/in/catalin-lupascu/"><i className="lab la-linkedin"></i></a>
                </li>
                <li>
                    <a href="https://www.facebook.com/catallinu"><i className="lab la-facebook"></i></a>
                </li>
                <li>
                    <a href="https://www.instagram.com/catallinu"><i className="lab la-instagram"></i></a>
                </li>
                <li>
                    <a href="https://github.com/clupascu97"><i className="lab la-github"></i></a>
                </li>
                <li>
                    <a href="https://api.whatsapp.com/send?phone=+37369116338"><i className="lab la-whatsapp"></i></a>
                </li>
            </ul>
            <a href="#contact" className="theme-btn">
                <i className="las la-envelope"></i> Hire Me!
            </a>
        </div>
    )
}

export default LeftSidebar
