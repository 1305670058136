import React from 'react'
import LeftSidebar from './Global-content/LeftSidebar'
import Hero from './Main-Components/Hero'
import About from './Main-Components/About'
import Resume from './Main-Components/Resume'
import Service from './Main-Components/Service'
import Skills from './Main-Components/Skills'
import Portfolio from './Main-Components/Portfolio'
import Contact from './Main-Components/Contact'

export default function Main() {
    return (
        <main className="catalin-main">
            <div id="smooth-wrapper">
                <div id="smooth-content">

                    <LeftSidebar/>

                    <Hero/>
                    <About/>
                    <Resume/>
                    <Service/>
                    <Skills/>
                    <Portfolio/>
                    <Contact/>

                </div>
            </div>
        </main>
    )
}
