import React, {useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Skills() {
    useEffect(() => {
        AOS.init({duration: 1000})
    }, [])
    return (
        <section className="skills-area page-section scroll-to-page" id="skills">
            <div className="custom-container">
                <div className="skills-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="las la-shapes"></i> my skills
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>My <span>Advantages</span></h1>
                    </div>


                    <div className="row skills text-center">
                        <div className="col-md-3 scroll-animation" data-aos='fade-left'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/tech/react.png" alt="React"/>
                                    <h1 className="name">React</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-up'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/tech/angular.png" alt="Angular"/>
                                    <h1 className="name">Angular</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-up'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/tech/typescript.png" alt="Typescript"/>
                                    <h1 className="name">Typescript</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-down'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/tech/nextjs.png" alt="Next.js"/>
                                    <h1 className="name">Next.js</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-down'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/tech/nodejs.png" alt="Next.js"/>
                                    <h1 className="name">Node.js</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-down'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/tech/nestjs.svg" alt="Next.js"/>
                                    <h1 className="name">Nest.js</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/tech/vercel.png" alt="Vercel"/>
                                    <h1 className="name">Vercel</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/tech/graphql.png" alt="Graphql"/>
                                    <h1 className="name">GraphQl</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/tech/pusher.png" alt="Pusher"/>
                                    <h1 className="name">Pusher</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/tech/cypress.png" alt="Cypress"/>
                                    <h1 className="name">Cypress</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/tech/storybook.png" alt="Storybook"/>
                                    <h1 className="name">Storybook</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/tech/figma.png" alt="Figma"/>
                                    <h1 className="name">Figma</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/tech/wix.png" alt="Wix"/>
                                    <h1 className="name">Wix</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/tech/firebase.png" alt="Firebase"/>
                                    <h1 className="name">Firebase</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 scroll-animation" data-aos='fade-right'>
                            <div className="skill">
                                <div className="skill-inner">
                                    <img src="../assets/images/tech/stripe.png" alt="Firebase"/>
                                    <h1 className="name">Stripe</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
