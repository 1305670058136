import React, {useEffect, useRef, useState} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import emailjs from 'emailjs-com';

export default function Contact() {
    useEffect(() => {
        AOS.init({duration: 1300});
    }, []);

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        subject: '',
        message: '',
    });

    const [attachment, setAttachment] = useState(null);
    const formRef = useRef(null);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleFileChange = (e) => {
        setAttachment(e.target.files[0]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const serviceID = process.env.REACT_APP_MAIL_SERVICE_ID;
        const templateID = process.env.REACT_APP_MAIL_TEMPLATE_ID;
        const userID = process.env.REACT_APP_MAIL_USER_ID;

        const form = new FormData();
        form.append('fullName', formData.fullName);
        form.append('email', formData.email);
        form.append('subject', formData.subject);
        form.append('message', formData.message);

        if (attachment) {
            form.append('attachment', attachment, attachment.name);
        }

        emailjs.sendForm(serviceID, templateID, formRef.current, userID)
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                document.querySelector('.alert-success').style.display = 'block';
            })
            .catch((error) => {
                console.log('FAILED...', error);
            });
    };

    return (
        <section className="contact-area page-section scroll-content" id="contact">
            <div className="custom-container">
                <div className="contact-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="las la-dollar-sign"></i> contact
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>Let's Work <span>Together!</span></h1>
                    </div>
                    <h3 className="scroll-animation" data-aos='fade-up'>clupascu97@gmail.com</h3>
                    <p id="required-msg">* Marked fields are required to fill.</p>

                    <form className="contact-form scroll-animation" data-aos='fade-up' onSubmit={handleSubmit}
                          ref={formRef}>
                        <div className="alert alert-success messenger-box-contact__msg" style={{display: 'none'}}
                             role="alert">
                            Your message was sent successfully.
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="input-group">
                                    <label htmlFor="fullName">Full Name <sup>*</sup></label>
                                    <input type="text" name="fullName" id="fullName" placeholder="Your Full Name"
                                           value={formData.fullName} onChange={handleInputChange} required/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input-group">
                                    <label htmlFor="email">Email <sup>*</sup></label>
                                    <input type="email" name="email" id="email" placeholder="Your email address"
                                           value={formData.email} onChange={handleInputChange} required/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input-group">
                                    <label htmlFor="subject">Subject <sup>*</sup></label>
                                    <input type="text" name="subject" id="subject" placeholder="Subject"
                                           value={formData.subject} onChange={handleInputChange} required/>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="input-group">
                                    <label htmlFor="message">Message</label>
                                    <textarea name="message" id="message" placeholder="Write your message here ..."
                                              value={formData.message} onChange={handleInputChange} required></textarea>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="input-group upload-attachment">
                                    <div>
                                        <label htmlFor="upload-attachment">
                                            <i className="las la-cloud-upload-alt"></i> add an attachment
                                            <input type="file" name="file" id="upload-attachment"
                                                   onChange={handleFileChange}/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="input-group submit-btn-wrap">
                                    <button className="theme-btn" type="submit">Send Message</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}